import React, { useState } from 'react';
import { Row, Col, Badge, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEdit } from '@fortawesome/free-solid-svg-icons';

import { useUser } from '../../contexts/UserContext';
import { useFetchClaim } from '../../services/claims';
import { ucwords } from '../../lib/strings';
import dayjs from '../../lib/day';
import Loading from '../common/Loading';
import Tabs from '../common/Tabs';
import FileBrowser from '../fileBrowser/FileBrowser';
import QuestionnaireResults from '../questionnaire/QuestionnaireResults';
import ClaimTranscripts from './components/ClaimTranscripts';

import EditClaimModal from './EditClaimModal';

const BadgeStyled = styled(Badge)`
  font-size: 1.15rem;
`;

const ListStyled = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 3fr;
`;

const DisplayStyled = styled.div`
  margin-bottom: 0.5rem;

  & > label {
    display: block;
    font-weight: 200;
    color: rgba(0, 0, 0, 0.6);
  }

  & > span {
    font-weight: 400;
  }
`;

const Claim = ({ claimId, onBack, headerContent = null }) => {
  const { user } = useUser();
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading, data: claim, isError, error } = useFetchClaim(claimId);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Alert color="danger">{error.message}</Alert>;
  }

  const tabs = [
    {
      key: 'files',
      label: 'Files',
      content: (
        <>
          {claim.questionnaire ? (
            <FileBrowser
              claimId={claim.claimId}
              files={claim.files}
              onRefresh={() => {
                queryClient.invalidateQueries('files');
                toast.success('Your file was successfully uploaded.');
              }}
            />
          ) : (
            <Alert variant="info" className="text-center">
              <h4>It looks like you have not completed your questionnaire.</h4>
              <p>
                <Link to={`/questionnaire?claimId=${claimId}`}>
                  Complete your questionnaire here.
                </Link>
              </p>
            </Alert>
          )}
        </>
      ),
    },
    {
      key: 'current',
      label: 'Current Status',
      content: (
        <>
          <DisplayStyled>
            <label>Email</label>
            <span>
              <a
                href={`mailto:claim.user.userEmail`}
                target="_blank"
                rel="noreferrer"
              >
                {claim.user.userEmail}
              </a>
            </span>
          </DisplayStyled>
          <ListStyled>
            <DisplayStyled>
              <label>Phone</label>
              <span>{claim.user.userPhone || '--'}</span>
            </DisplayStyled>
            <DisplayStyled>
              <label>Phone Type</label>
              <span>{ucwords(claim.user.userPhoneType)}</span>
            </DisplayStyled>
            <DisplayStyled>
              <label>Date of Birth</label>
              <span>
                {dayjs.utc(claim.user.userDateOfBirth).format('MM/DD/YYYY')}
              </span>
            </DisplayStyled>
            <DisplayStyled>
              <label>SSN</label>
              <span>{claim.user.userSsn || '-'}</span>
            </DisplayStyled>
            <DisplayStyled>
              <label>Married</label>
              <span>{claim.user.userMarried ? 'Yes' : 'No'}</span>
            </DisplayStyled>
            <DisplayStyled>
              <label># of Dependents</label>
              <span>{claim.user.userDependentsUnder18}</span>
            </DisplayStyled>
            <DisplayStyled>
              <label>Military Status</label>
              <span>{ucwords(claim.user.userMilitaryStatus)}</span>
            </DisplayStyled>
            <DisplayStyled>
              <label>Combat Veteran</label>
              <span>{claim.user.userCombatVeteran ? 'Yes' : 'No'}</span>
            </DisplayStyled>
            <DisplayStyled>
              <label>Discharge Type</label>
              <span>{ucwords(claim.user.userDischargeType)}</span>
            </DisplayStyled>
            <DisplayStyled>
              <label>Current VA Rating</label>
              <span>{claim.claimCurrentRating}%</span>
            </DisplayStyled>
            <DisplayStyled>
              <label>Desired VA Rating</label>
              <span>{claim.claimDesiredRating}%</span>
            </DisplayStyled>
            <DisplayStyled>
              <label>Created On</label>
              <span>
                {dayjs.utc(claim.claimCreatedOn).format('MM/DD/YYYY')}
              </span>
            </DisplayStyled>
            <DisplayStyled>
              <label>Service Dates</label>
              <span>
                {claim.user.userServiceDates
                  .map((dt, idx) => {
                    return `${dt.start} to ${dt.end}`;
                  })
                  .join(', ')}
              </span>
            </DisplayStyled>
            {claim.claimRatingsBreakdown.map((c, cIdx) => {
              return (
                <DisplayStyled key={cIdx}>
                  <label>{c.label}</label>
                  <span>{c.rating}%</span>
                </DisplayStyled>
              );
            })}
          </ListStyled>
        </>
      ),
    },
    {
      key: 'questionnaire',
      label: 'Questionnaire',
      content: (
        <QuestionnaireResults claimId={claimId} answers={claim.questionnaire} />
      ),
    },
  ];

  if (user.userType === 'admin') {
    tabs.push({
      key: 'transcripts',
      label: 'Transcripts',
      content: (
        <ClaimTranscripts
          claim={claim}
          transcripts={claim._transcripts || []}
        />
      ),
    });
  }

  const flexCols = (
    <Row>
      <Col>
        <DisplayStyled>
          <label>Email</label>
          <span>
            <a
              href={`mailto:${claim.user.userEmail}`}
              target="_blank"
              rel="noreferrer"
            >
              {claim.user.userEmail}
            </a>
          </span>
        </DisplayStyled>
      </Col>
      <Col>
        <DisplayStyled>
          <label>Assigned To</label>
          {claim.assignedUser ? (
            <span>
              <a
                href={`mailto:${claim.assignedUser.userEmail}`}
                target="_blank"
                rel="noreferrer"
              >
                {claim.assignedUser.userFirstName}{' '}
                {claim.assignedUser.userLastName}
              </a>
            </span>
          ) : (
            <span>-</span>
          )}
        </DisplayStyled>
      </Col>
    </Row>
  );

  return (
    <>
      {user.userType === 'admin' ? (
        <>
          <Row>
            <Col>
              {onBack ? (
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    if (typeof onBack === 'function') {
                      onBack();
                    }
                  }}
                  size="sm"
                  className="float-start me-3 mt-1"
                >
                  <FontAwesomeIcon icon={faChevronLeft} /> Go Back
                </Button>
              ) : null}
              <h3>
                {claim.userLastName}, {claim.userFirstName}
              </h3>
            </Col>
            <Col className="text-end">
              <Button
                variant="outline-secondary"
                onClick={() => setEdit(true)}
                className="mt-2"
              >
                <FontAwesomeIcon icon={faEdit} /> Edit
              </Button>
            </Col>
          </Row>
          <hr />
        </>
      ) : (
        <>
          <Row>
            <Col>
              <h1>
                Welcome, {user.userFirstName} {user.userLastName}.
              </h1>
            </Col>
            <Col className="text-end">
              <Button
                variant="outline-secondary"
                onClick={() => setEdit(true)}
                className="mt-2"
              >
                <FontAwesomeIcon icon={faEdit} /> Edit
              </Button>
            </Col>
          </Row>
          <hr />
        </>
      )}

      <Row>
        <Col xs={12} sm={6} md={headerContent ? 8 : 6}>
          <Row>
            <Col>
              <BadgeStyled size="xl" bg="success" className="mt-2">
                Status: {ucwords(claim.claimStatus)}
              </BadgeStyled>
            </Col>
            <Col>
              <DisplayStyled>
                <label>VA Claim Type</label>
                <span style={{ fontWeight: 700 }}>
                  {ucwords(claim.claimVaType)}
                </span>
              </DisplayStyled>
            </Col>
          </Row>
          {headerContent ? flexCols : null}
        </Col>
        <Col xs={12} sm={6} md={headerContent ? 4 : 6}>
          {headerContent ? headerContent : flexCols}
        </Col>
      </Row>

      <br />
      <br />

      <Tabs tabs={tabs} />

      <br />
      <br />

      <EditClaimModal
        show={edit}
        toggle={() => setEdit(false)}
        claim={claim}
        onSuccess={() => queryClient.invalidateQueries('claim')}
      />
    </>
  );
};

export default Claim;
