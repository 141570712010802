import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinus,
  faPlus,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

import { useUser } from '../../contexts/UserContext';
import { useForm } from '../../hooks/useForm';
import { useAuthRegister } from '../../services/auth';
import { useFetchAuthInvitation } from '../../services/invitations';
import PublicLayout from '../layouts/PublicLayout';
import Panel from '../common/Panel';
import AdminSelect from '../common/AdminSelect';
import TimezoneSelect from '../common/TimezoneSelect';
import StateSelect from '../common/StateSelect';

const Register = () => {
  const { user, verifyId, setVerifyId } = useUser();
  const { form, onChange, setValues } = useForm({
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userDateOfBirth: '',
    userCombatVeteran: false,
    userMarried: false,
    userDependentsUnder18: 0,
    userCurrentVaRating: 0,
    userMilitaryStatus: 'separated',
    userIsVietnamVeteran: false,
    userTimezone: moment.tz.guess(),
    userState: '',
    userServiceDates: [{ start: '', end: '' }],
    userDischargeType: 'Honorable',
    userReferredBy: null,
    userSsn: '',
    inviteId: null,
    claimDesiredRating: 0,
    claimRatingsBreakdown: [
      {
        label: '',
        rating: 0,
      },
    ],
    adminSelect: null,
    userPartnerId: null,
  });
  const [inviteId, setInviteId] = useState(null);
  const [partner, setPartner] = useState(null);
  const claimDesiredRatingRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading: inviteLoading, data: invite } =
    useFetchAuthInvitation(inviteId);

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }

    if (location.state && location.state.userReferredBy) {
      setValues(
        {
          adminSelect: location.state.userReferredBy,
          userReferredBy: location.state.userReferredBy,
        },
        true
      );
    }

    if (location.state && location.state.partner) {
      setValues({ userPartnerId: location.state.partner.partnerId }, true);
      setPartner(location.state.partner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const id = new URLSearchParams(location.search).get('inviteId');
    setInviteId(id || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!inviteId) {
      return;
    }

    if (invite) {
      setValues(
        {
          inviteId: invite.inviteId,
          userReferredBy: invite.inviteByUserId,
          userEmail: invite.inviteEmail,
          ...invite.inviteMeta,
        },
        true
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite]);

  useEffect(() => {
    if (verifyId) {
      navigate('/verify?redirectTo=questionnaire');
    }
  }, [verifyId, navigate]);

  const callApi = useAuthRegister(
    (res) => {
      setVerifyId(res.userId);
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();

    // validate the desired rating
    const currentRating = Number(form.userCurrentVaRating);
    const desiredRating = Number(form.claimDesiredRating);
    if (
      isNaN(currentRating) ||
      isNaN(desiredRating) ||
      desiredRating <= 0 ||
      desiredRating <= currentRating
    ) {
      toast.error('You must enter a desired VA rating before continuing.');
      claimDesiredRatingRef.current.focus();
      return;
    }

    // build the payload
    const payload = {
      ...form,
      claimRatingsBreakdown: form.claimRatingsBreakdown.filter(
        (item) => item.label.trim() !== ''
      ),
    };

    callApi.mutate(payload);
  };

  const onAddItem = () => {
    const newItems = [...form.claimRatingsBreakdown, { label: '', rating: 0 }];
    onChange({ target: { name: 'claimRatingsBreakdown', value: newItems } });
  };

  const onMinusItem = (itemIdx) => {
    const newItems = [];
    form.claimRatingsBreakdown.forEach((item, idx) => {
      if (idx !== itemIdx) {
        newItems.push(item);
      }
    });
    onChange({ target: { name: 'claimRatingsBreakdown', value: newItems } });
  };

  const renderBreakdown = () => {
    const items = form.claimRatingsBreakdown.map((item, itemIdx) => {
      return (
        <Row key={itemIdx} className="mb-3">
          <Col>
            <Form.Control
              name={`claimRatingsBreakdown.${itemIdx}.label`}
              value={item.label}
              onChange={onChange}
              placeholder="Condition"
              required={itemIdx > 0}
            />
          </Col>
          <Col>
            <Form.Control
              type="number"
              min={0}
              max={100}
              step={5}
              name={`claimRatingsBreakdown.${itemIdx}.rating`}
              value={item.rating}
              onChange={onChange}
            />
          </Col>
          <Col xs={3} className="text-end">
            <Button variant="secondary" onClick={() => onAddItem()}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>{' '}
            <Button
              variant="secondary"
              onClick={() => onMinusItem(itemIdx)}
              disabled={form.claimRatingsBreakdown.length === 1}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </Col>
        </Row>
      );
    });

    return <div className="mb-3">{items}</div>;
  };

  const renderServiceDates = () => {
    const items = form.userServiceDates.map((item, itemIdx) => {
      return (
        <Row key={itemIdx} className="mb-3">
          <Col>
            <Form.Control
              type="date"
              name={`userServiceDates.${itemIdx}.start`}
              value={item.start}
              onChange={onChange}
              placeholder="Start"
              required
            />
          </Col>
          <Col>
            <Form.Control
              type="date"
              name={`userServiceDates.${itemIdx}.end`}
              value={item.end}
              onChange={onChange}
              placeholder="End"
              required
            />
          </Col>
          <Col xs={3} className="text-end">
            <Button
              variant="secondary"
              onClick={() => {
                onChange({
                  target: {
                    name: 'userServiceDates',
                    value: [...form.userServiceDates, { start: '', end: '' }],
                  },
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>{' '}
            <Button
              variant="secondary"
              onClick={() => {
                const newDates = [...form.userServiceDates].filter(
                  (u, i) => i !== itemIdx
                );
                onChange({
                  target: {
                    name: 'userServiceDates',
                    value: newDates,
                  },
                });
              }}
              disabled={form.userServiceDates.length === 1}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </Col>
        </Row>
      );
    });

    return <div className="mb-3">{items}</div>;
  };

  return (
    <PublicLayout>
      <Row>
        <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }}>
          <Panel className="mb-3 pb-3 text-center">
            <h3>New Users</h3>
            <p>
              Ready to start a new claim with us? Just complete the form below
              to get started.
            </p>
          </Panel>

          {inviteLoading ? (
            <Panel className="mb-3 pb-3 text-center">
              <h5>Checking invite details...</h5>
            </Panel>
          ) : null}

          <form onSubmit={onSubmit}>
            {!form.inviteId && !form.userPartnerId ? (
              <Panel className="mb-3">
                <Row>
                  <Col xs={12} sm={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Who were you referred by?</Form.Label>
                      <AdminSelect
                        usePublic={true}
                        value={form.adminSelect}
                        onChange={(val) => {
                          setValues({
                            ...form,
                            adminSelect: val,
                            userReferredBy: val ? val.value : null,
                          });
                        }}
                        showDefaultOptions={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Panel>
            ) : null}

            {form.userPartnerId ? (
              <Panel className="mb-3">
                <h4>Welcome, {partner.partnerName} user!</h4>
                {partner.partnerDetails ? (
                  <div className="mb-3">
                    {partner.partnerDetails.split('\n').map((item, key) => {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                  </div>
                ) : null}
              </Panel>
            ) : null}

            <Panel className="mb-3">
              <Row>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Your First Name</Form.Label>
                    <Form.Control
                      name="userFirstName"
                      value={form.userFirstName}
                      onChange={onChange}
                      placeholder="First Name"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Your Last Name</Form.Label>
                    <Form.Control
                      name="userLastName"
                      value={form.userLastName}
                      onChange={onChange}
                      placeholder="Last Name"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Your Email</Form.Label>
                    <Form.Control
                      name="userEmail"
                      value={form.userEmail}
                      onChange={onChange}
                      placeholder="your@email.com"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Your Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      name="userDateOfBirth"
                      value={form.userDateOfBirth}
                      onChange={onChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Your Phone</Form.Label>
                    <Form.Control
                      type="phone"
                      name="userPhone"
                      value={form.userPhone}
                      onChange={onChange}
                      as={InputMask}
                      mask="999-999-9999"
                      placeholder="111-111-1111"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Your Phone Type</Form.Label>
                    <Form.Select
                      name="userPhoneType"
                      value={form.userPhoneType}
                      onChange={onChange}
                    >
                      <option value="mobile">Mobile</option>
                      <option value="land">Land Line</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Your SSN</Form.Label>
                    <Form.Control
                      name="userSsn"
                      value={form.userSsn}
                      onChange={onChange}
                      as={InputMask}
                      mask="999-99-9999"
                      placeholder="###-##-####"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Panel>

            <Panel className="mb-3">
              <Row>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Are You Married?</Form.Label>
                    <Form.Select
                      name="userMarried"
                      onChange={onChange}
                      value={form.userMarried}
                    >
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Dependents under the age of 18?</Form.Label>
                    <Form.Control
                      type="number"
                      min={0}
                      max={10}
                      step={1}
                      name="userDependentsUnder18"
                      value={form.userDependentsUnder18}
                      onChange={onChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Panel>

            <Panel className="mb-3">
              <Row>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Current military status?</Form.Label>
                    <Form.Select
                      name="userMilitaryStatus"
                      onChange={onChange}
                      value={form.userMilitaryStatus}
                    >
                      <option value="separated">Separated</option>
                      <option value="national guard">National Guard</option>
                      <option value="transitioning">Transitioning</option>
                      <option value="retired">Retired</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Are You A Vietnam Era Veteran?</Form.Label>
                    <Form.Select
                      name="userIsVietnamVeteran"
                      onChange={onChange}
                      value={form.userIsVietnamVeteran}
                    >
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Are you a combat veteran?</Form.Label>
                    <Form.Select
                      name="userCombatVeteran"
                      onChange={onChange}
                      value={form.userCombatVeteran}
                    >
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      What discharge did you receive/expect?
                    </Form.Label>
                    <Form.Select
                      name="userDischargeType"
                      onChange={onChange}
                      value={form.userDischargeType}
                    >
                      <option value="Honorable">Honorable</option>
                      <option value="Under Honorable Conditions (General)">
                        Under Honorable Conditions (General)
                      </option>
                      <option value="Under Other than Honorable Conditions (OTH)">
                        Under Other than Honorable Conditions (OTH)
                      </option>
                      <option value="Bad Conduct">Bad Conduct</option>
                      <option value="Dishonorable">Dishonorable</option>
                      <option value="Uncharacterized">Uncharacterized</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Panel>

            <Panel className="mb-3">
              <p>Service Dates</p>
              {renderServiceDates()}
            </Panel>

            <Panel className="mb-3">
              <Row>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Current VA rating?</Form.Label>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      step={5}
                      name="userCurrentVaRating"
                      value={form.userCurrentVaRating}
                      onChange={onChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Desired VA rating?</Form.Label>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      step={5}
                      name="claimDesiredRating"
                      value={form.claimDesiredRating}
                      onChange={onChange}
                      ref={claimDesiredRatingRef}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Panel>

            <Panel className="mb-3">
              <Row>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>State</Form.Label>
                    <StateSelect
                      name="userState"
                      onChange={onChange}
                      value={form.userState}
                      required={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Timezone</Form.Label>
                    <TimezoneSelect
                      name="userTimezone"
                      onChange={onChange}
                      value={form.userTimezone}
                      require={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Panel>

            <Panel className="mb-3">
              <p>Current VA rating breakdown:</p>
              {renderBreakdown()}
            </Panel>

            <Panel className="mb-3 pb-3">
              <Button
                type="submit"
                variant="primary"
                disabled={callApi.isLoading}
              >
                <FontAwesomeIcon icon={faPaperPlane} /> Submit
              </Button>
            </Panel>
          </form>
        </Col>
      </Row>
    </PublicLayout>
  );
};

export default Register;
