import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPlus,
  faMinus,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import dayjs from '../../lib/day';
import { useUser } from '../../contexts/UserContext';
import { useForm } from '../../hooks/useForm';
import { useUpdateClaimWithUser } from '../../services/claims';
import TimezoneSelect from '../common/TimezoneSelect';
import StateSelect from '../common/StateSelect';

const EditClaimModal = ({
  show,
  toggle,
  claim,
  onSuccess,
  includeNotes = false,
}) => {
  const { user } = useUser();
  const { form, onChange, setValues } = useForm({ ...claim, ...claim.user });
  const [showNotice, setShowNotice] = useState(false);

  const updateClaimWithUser = useUpdateClaimWithUser(
    (res) => {
      if (typeof onSuccess === 'function') {
        onSuccess(res);
      }
      toggle();
    },
    { onError: (err) => toast.error(err.message) }
  );

  useEffect(() => {
    setValues({
      ...claim,
      ...claim.user,
      userDateOfBirth: dayjs
        .utc(claim.user.userDateOfBirth)
        .format('YYYY-MM-DD'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim, show]);

  useEffect(() => {
    if (show) {
      setShowNotice(false);
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (claim.claimCurrentRating !== form.claimCurrentRating && !showNotice) {
      setShowNotice(true);
      return;
    }

    updateClaimWithUser.mutate({
      claimId: claim.claimId,
      payload: { ...form, claimCurrentRating: form.claimCurrentRating },
    });
  };

  const onAddItem = () => {
    const newItems = [...form.claimRatingsBreakdown, { label: '', rating: 0 }];
    onChange({ target: { name: 'claimRatingsBreakdown', value: newItems } });
  };

  const onMinusItem = (itemIdx) => {
    const newItems = [];
    form.claimRatingsBreakdown.forEach((item, idx) => {
      if (idx !== itemIdx) {
        newItems.push(item);
      }
    });
    onChange({ target: { name: 'claimRatingsBreakdown', value: newItems } });
  };

  const renderBreakdown = () => {
    let total = 0;

    const items = form.claimRatingsBreakdown.map((item, itemIdx) => {
      if (item.rating) {
        total += Number(item.rating);
      }

      return (
        <Row key={itemIdx} className="mb-3">
          <Col>
            <Form.Control
              name={`claimRatingsBreakdown.${itemIdx}.label`}
              value={item.label}
              onChange={onChange}
              placeholder="Condition"
              required
            />
          </Col>
          <Col>
            <Form.Control
              type="number"
              min={0}
              max={100}
              step={5}
              name={`claimRatingsBreakdown.${itemIdx}.rating`}
              value={item.rating}
              onChange={onChange}
              required
            />
          </Col>
          <Col xs={3} className="text-end">
            <Button variant="secondary" onClick={() => onAddItem()}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>{' '}
            <Button
              variant="secondary"
              onClick={() => onMinusItem(itemIdx)}
              disabled={form.claimRatingsBreakdown.length === 1}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </Col>
        </Row>
      );
    });

    return (
      <div className="mb-4">
        {items}
        <hr />
        <Row>
          <Col>Total:</Col>
          <Col className="text-end">{total}%</Col>
        </Row>
      </div>
    );
  };

  const renderServiceDates = () => {
    const items = form.userServiceDates.map((item, itemIdx) => {
      return (
        <Row key={itemIdx} className="mb-3">
          <Col>
            <Form.Control
              type="date"
              name={`userServiceDates.${itemIdx}.start`}
              value={item.start}
              onChange={onChange}
              placeholder="Start"
              required
            />
          </Col>
          <Col>
            <Form.Control
              type="date"
              name={`userServiceDates.${itemIdx}.end`}
              value={item.end}
              onChange={onChange}
              placeholder="End"
              required
            />
          </Col>
          <Col xs={3} className="text-end">
            <Button
              variant="secondary"
              onClick={() => {
                onChange({
                  target: {
                    name: 'userServiceDates',
                    value: [...form.userServiceDates, { start: '', end: '' }],
                  },
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>{' '}
            <Button
              variant="secondary"
              onClick={() => {
                const newDates = [...form.userServiceDates].filter(
                  (u, i) => i !== itemIdx
                );
                onChange({
                  target: {
                    name: 'userServiceDates',
                    value: newDates,
                  },
                });
              }}
              disabled={form.userServiceDates.length === 1}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </Col>
        </Row>
      );
    });

    return <div className="mb-4">{items}</div>;
  };

  const renderButtons = (btnVariant = 'success') => {
    return (
      <>
        <Button
          type="submit"
          variant={btnVariant}
          disabled={updateClaimWithUser.isLoading}
        >
          <FontAwesomeIcon
            icon={updateClaimWithUser.isLoading ? faSpinner : faCheck}
            spin={updateClaimWithUser.isLoading}
          />{' '}
          Save Changes
        </Button>
        <Button variant="link" onClick={() => toggle()}>
          Cancel
        </Button>
      </>
    );
  };

  return (
    <Modal size="lg" show={show} onHide={() => toggle()}>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <Row>
            <Col xs={12} md={6} className="mb-3">
              <Form.Group className="mb-3">
                <Form.Label>Your First Name</Form.Label>
                <Form.Control
                  name="userFirstName"
                  value={form.userFirstName}
                  onChange={onChange}
                  placeholder="First Name"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Your Last Name</Form.Label>
                <Form.Control
                  name="userLastName"
                  value={form.userLastName}
                  onChange={onChange}
                  placeholder="Last Name"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  name="userEmail"
                  value={form.userEmail}
                  onChange={onChange}
                  placeholder="your@email.com"
                  required
                  readOnly
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Your Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="userDateOfBirth"
                  value={form.userDateOfBirth}
                  onChange={onChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Your SSN</Form.Label>
                <Form.Control
                  name="userSsn"
                  value={form.userSsn || ''}
                  onChange={onChange}
                  as={InputMask}
                  mask="999-99-9999"
                  placeholder="###-##-####"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Your Phone</Form.Label>
                <Form.Control
                  type="phone"
                  name="userPhone"
                  value={form.userPhone}
                  onChange={onChange}
                  as={InputMask}
                  mask="999-999-9999"
                  placeholder="111-111-1111"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Your Phone Type</Form.Label>
                <Form.Select
                  name="userPhoneType"
                  value={form.userPhoneType}
                  onChange={onChange}
                >
                  <option value="mobile">Mobile</option>
                  <option value="land">Land Line</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Are You Married?</Form.Label>
                <Form.Select
                  name="userMarried"
                  onChange={onChange}
                  value={form.userMarried}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Dependents under the age of 18?</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  max={10}
                  step={1}
                  name="userDependentsUnder18"
                  value={form.userDependentsUnder18}
                  onChange={onChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="mb-3">
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <StateSelect
                  name="userState"
                  onChange={onChange}
                  value={form.userState}
                  required={true}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Timezone</Form.Label>
                <TimezoneSelect
                  name="userTimezone"
                  onChange={onChange}
                  value={form.userTimezone}
                  require={true}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Current military status?</Form.Label>
                <Form.Select
                  name="userMilitaryStatus"
                  onChange={onChange}
                  value={form.userMilitaryStatus}
                >
                  <option value="separated">Separated</option>
                  <option value="national guard">National Guard</option>
                  <option value="transitioning">Transitioning</option>
                  <option value="retired">Retired</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Are You A Vietnam Era Veteran?</Form.Label>
                <Form.Select
                  name="userIsVietnamVeteran"
                  onChange={onChange}
                  value={form.userIsVietnamVeteran}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Are You A Combat Veteran?</Form.Label>
                <Form.Select
                  name="userCombatVeteran"
                  onChange={onChange}
                  value={form.userCombatVeteran}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>What discharge did you receive/expect?</Form.Label>
                <Form.Select
                  name="userDischargeType"
                  onChange={onChange}
                  value={form.userDischargeType}
                >
                  <option value="Honorable">Honorable</option>
                  <option value="Under Honorable Conditions (General)">
                    Under Honorable Conditions (General)
                  </option>
                  <option value="Under Other than Honorable Conditions (OTH)">
                    Under Other than Honorable Conditions (OTH)
                  </option>
                  <option value="Bad Conduct">Bad Conduct</option>
                  <option value="Dishonorable">Dishonorable</option>
                  <option value="Uncharacterized">Uncharacterized</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Current VA rating?</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  max={100}
                  step={5}
                  name="claimCurrentRating"
                  value={form.claimCurrentRating}
                  onChange={onChange}
                  readOnly={user.userType === 'admin' ? false : true}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Desired VA rating?</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  max={100}
                  step={5}
                  name="claimDesiredRating"
                  value={form.claimDesiredRating}
                  onChange={onChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <p>Service Dates</p>
          {renderServiceDates()}

          <p>Current VA rating breakdown:</p>
          {renderBreakdown()}

          {includeNotes ? (
            <div>
              <Form.Label>Notes (Client will not see this)</Form.Label>
              <MDEditor
                value={form.claimNotes || ''}
                onChange={(v) =>
                  onChange({ target: { name: 'claimNotes', value: v } })
                }
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
                preview="edit"
              />
            </div>
          ) : null}

          <hr />

          {renderButtons()}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditClaimModal;
