export const CLAIM_STATES = {
  intake: {
    canMoveTo: [
      'awaiting medical records',
      'in process',
      'awaiting internal review',
      'dead',
    ],
  },
  'awaiting medical records': {
    canMoveTo: ['intake', 'in process', 'awaiting internal review', 'dead'],
  },
  'in process': {
    canMoveTo: [
      'intake',
      'awaiting medical records',
      'awaiting internal review',
      'dead',
    ],
    helpMessage:
      'You must move to "Awaiting Internal Review" from here to continue in the pipeline.',
  },
  'awaiting internal review': {
    canMoveTo: [
      'intake',
      'awaiting medical records',
      'in process',
      'internal review approved',
      'dead',
    ],
  },
  'internal review approved': {
    canMoveTo: [
      'intake',
      'awaiting medical records',
      'in process',
      'awaiting internal review',
      'submitted to va',
      'dead',
    ],
    allowedRoles: ['admin'], // only the following scan transtion TO this state
    allowedEmails: ['corbitt@veritasratings.com'], // we have some non-admin users that should be able to deal with this as well
    helpMessage:
      'Can only be moved to this status by authorized users. This step is required before going to "Submitted to VA".',
  },
  'submitted to va': {
    canMoveTo: [
      'awaiting medical records',
      'in process',
      'awaiting internal review',
      'invoiced',
      'dead',
    ],
  },
  invoiced: {
    canMoveTo: ['payment plan', 'paid'],
    allowedRoles: ['admin'],
  },
  'payment plan': {
    canMoveTo: ['paid'],
  },
  paid: {
    canMoveTo: ['payment plan', 'invoiced'],
  },
  dead: {
    canMoveTo: [
      'intake',
      'awaiting medical records',
      'in process',
      'awaiting internal review',
    ],
  },
};

export const availableTransitions = (
  status,
  userRole = 'user',
  userEmail = null
) => {
  if (!status || !CLAIM_STATES[status]) {
    throw new Error(`Invalid status provided: ${status}`);
  }

  const state = CLAIM_STATES[status];

  const canMoveTo = state.canMoveTo.filter((s) => {
    const toState = CLAIM_STATES[s];

    switch (true) {
      case userRole &&
        toState.allowedRoles &&
        userEmail &&
        toState.allowedEmails:
        return (
          toState.allowedRoles.includes(userRole) ||
          toState.allowedEmails.includes(userEmail)
        );

      case userRole && toState.allowedRoles:
        return toState.allowedRoles.includes(userRole);

      case userEmail && toState.allowedEmails:
        return toState.allowedEmails.includes(userEmail);

      default:
        return true;
    }
  });

  return canMoveTo;
};
