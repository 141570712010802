import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { noop } from '../lib/serviceHelpers';

export const useFetchClaims = (params, opts = {}) => {
  return useQuery(
    ['claim', params],
    async () => {
      const res = await axios.get(`/claims`, { params });
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      ...opts,
    }
  );
};

export const useFetchClaim = (claimId) => {
  return useQuery(
    ['claim', claimId],
    async () => {
      const res = await axios.get(`/claims/${claimId}?user=1`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );
};

export const useFetchClaimProfile = (claimId) => {
  return useQuery(
    ['claim-profile', claimId],
    async () => {
      const res = await axios.get(`/claims/${claimId}/profile`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );
};

export const useFetchCurrentClaim = () => {
  return useQuery(
    ['current-claim'],
    async () => {
      const res = await axios.get(`/claims/current`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );
};

export const useFetchQuestionnaireFields = (opts = {}) => {
  return useQuery(
    ['questionnaire'],
    async () => {
      const res = await axios.get(`/claims/questionnaire`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 500000,
      ...opts,
    }
  );
};

export const useUpdateClaimWithUser = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, payload }) => {
      const res = await axios.put(`/claims/${claimId}/with-user`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useUpdateClaim = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, payload }) => {
      const res = await axios.put(`/claims/${claimId}`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useDuplicateClaim = (callback, opts = {}) => {
  return useMutation(
    async (claimId) => {
      const res = await axios.put(`/claims/${claimId}/duplicate`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useSubmitQuestionnaire = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, payload }) => {
      const res = await axios.put(`/claims/${claimId}/questionnaire`, {
        ...payload,
      });
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useDeleteClaim = (callback, opts = {}) => {
  return useMutation(
    async (claimId) => {
      const res = await axios.delete(`/claims/${claimId}`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useDeleteClaimFile = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, fileId }) => {
      const res = await axios.delete(`/claims/${claimId}/files/${fileId}`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useGetPresignedClaimFile = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, fileId }) => {
      const res = await axios.get(`/claims/${claimId}/files/${fileId}/read`);
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useUpdateTranscript = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, transcriptId, payload }) => {
      const res = await axios.put(
        `/claims/${claimId}/profile/transcripts/${transcriptId}`,
        {
          ...payload,
        }
      );
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useReprocessAddon = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, addonId }) => {
      const res = await axios.put(
        `/claims/${claimId}/profile/addons/${addonId}/reprocess`
      );
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useToggleClaimTask = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, taskId }) => {
      const res = await axios.put(
        `/claims/${claimId}/profile/tasks/${taskId}/toggle`
      );
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};

export const useSetClaimAssignment = (callback, opts = {}) => {
  return useMutation(
    async ({ claimId, body }) => {
      const res = await axios.put(
        `/claims/${claimId}/profile/assignments`,
        body
      );
      return res.data;
    },
    { ...opts, onSuccess: callback || noop }
  );
};
